<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3 text-center">
        <h1>Email Verified</h1>
        <div>
          <svg
            class="illustration"
            viewBox="0 0 350 350"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M175 324.555C261.003 324.555 330.722 284.302 330.722 234.647C330.722 184.992 261.003 144.739 175 144.739C88.9969 144.739 19.2778 184.992 19.2778 234.647C19.2778 284.302 88.9969 324.555 175 324.555Z"
              fill="#F5F5F5"
            />
            <path
              d="M202.958 309.19L279.356 265.09C281.029 264.124 281.029 262.556 279.356 261.59L134.715 178.073C133.776 177.597 132.737 177.348 131.684 177.348C130.631 177.348 129.592 177.597 128.653 178.073L52.255 222.173C50.582 223.139 50.582 224.707 52.255 225.673L196.896 309.19C197.835 309.667 198.874 309.915 199.927 309.915C200.98 309.915 202.019 309.667 202.958 309.19Z"
              fill="#E0E0E0"
            />
            <path
              d="M66.864 223.846L72.856 220.388C72.856 220.388 74.319 207.564 73.346 197.631C71.946 183.68 62.958 166.887 52.206 170.828C41.454 174.769 57.106 188.986 60.606 200.823C64.106 212.66 66.864 223.846 66.864 223.846Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.15"
              d="M66.864 223.846L72.856 220.388C72.856 220.388 74.319 207.564 73.346 197.631C71.946 183.68 62.958 166.887 52.206 170.828C41.454 174.769 57.106 188.986 60.606 200.823C64.106 212.66 66.864 223.846 66.864 223.846Z"
              :fill="'#9078D4'"
            />
            <path
              d="M69.9998 222.39C69.9045 222.386 69.8145 222.345 69.749 222.276C69.6835 222.207 69.6479 222.114 69.6498 222.019C70.6998 191.009 55.2998 175.406 55.1108 175.252C55.0768 175.219 55.0497 175.179 55.0312 175.135C55.0127 175.091 55.0032 175.044 55.0032 174.996C55.0032 174.949 55.0127 174.902 55.0312 174.858C55.0497 174.814 55.0768 174.774 55.1108 174.741C55.1789 174.674 55.2707 174.636 55.3663 174.636C55.462 174.636 55.5537 174.674 55.6218 174.741C55.7758 174.902 71.4418 190.722 70.3778 222.047C70.3724 222.142 70.3297 222.232 70.259 222.296C70.1883 222.36 70.0952 222.394 69.9998 222.39V222.39Z"
              fill="white"
            />
            <path
              d="M63.7 226.324L67.9 223.86C67.9 223.86 68.6 215.061 64.316 204.372C60.557 195.062 51.24 187.341 46.55 188.972C41.86 190.603 42 193.9 44.289 197.4C46.578 200.9 60.48 209.195 63.7 226.324Z"
              :fill="'#9078D4'"
            />
            <path
              d="M65.9189 225.4C65.8224 225.4 65.7298 225.362 65.6615 225.293C65.5932 225.225 65.5549 225.132 65.5549 225.036C65.6389 205.436 47.2289 192.178 47.0399 192.045C46.9625 191.989 46.9103 191.904 46.8946 191.81C46.8789 191.716 46.9009 191.619 46.9559 191.541C46.9831 191.502 47.0178 191.469 47.0581 191.443C47.0983 191.418 47.1432 191.401 47.1902 191.393C47.2372 191.385 47.2852 191.387 47.3315 191.398C47.3778 191.409 47.4215 191.429 47.4599 191.457C47.6489 191.59 66.3599 205.058 66.2829 225.057C66.2759 225.149 66.2347 225.236 66.1674 225.299C66.1 225.362 66.0114 225.398 65.9189 225.4Z"
              fill="white"
            />
            <path
              d="M271.411 38.297L277.445 41.797C277.409 41.5324 277.39 41.2659 277.389 40.999V39.2C277.389 36.092 279.573 34.832 282.289 36.4C283.447 37.1138 284.438 38.0688 285.194 39.2C285.635 35.294 288.694 33.838 292.425 35.987C296.45 38.311 299.719 43.967 299.719 48.587V51.198C299.745 52.2426 299.532 53.2794 299.096 54.229L304.108 57.12C304.894 57.6323 305.548 58.3226 306.018 59.135C306.487 59.9474 306.758 60.8591 306.81 61.796C306.81 63.525 305.599 64.218 304.108 63.357L271.411 44.534C270.625 44.0217 269.97 43.3313 269.501 42.5189C269.031 41.7065 268.76 40.7949 268.709 39.858C268.709 38.129 269.92 37.429 271.411 38.297Z"
              fill="#EBEBEB"
            />
            <path
              d="M246.4 53.8229L250.817 56.3709C250.807 56.1774 250.807 55.9835 250.817 55.7899V54.5089C250.817 52.2269 252.413 51.3029 254.387 52.4089C255.225 52.9353 255.941 53.6346 256.487 54.4599C256.809 51.5899 259.056 50.5259 261.786 52.1009C263.341 53.1151 264.636 54.4814 265.565 56.0891C266.494 57.6968 267.032 59.5008 267.134 61.3549V63.2729C267.151 64.0374 266.996 64.7959 266.679 65.4919L270.347 67.5919C270.925 67.9668 271.406 68.4728 271.752 69.0688C272.097 69.6649 272.297 70.3341 272.335 71.0219C272.335 72.2889 271.446 72.7999 270.347 72.1629L246.4 58.4009C245.823 58.0242 245.342 57.5166 244.998 56.9194C244.654 56.3222 244.455 55.6522 244.419 54.9639C244.391 53.7039 245.273 53.1999 246.4 53.8229Z"
              fill="#EBEBEB"
            />
            <path
              d="M274.722 250.089C275.814 250.929 275.695 252.126 274.379 252.889L202.3 294.518C201.487 294.929 200.589 295.143 199.678 295.143C198.768 295.143 197.87 294.929 197.057 294.518L59.2129 214.949C57.8759 214.179 57.7709 212.968 58.9049 212.149C58.2268 212.661 57.6683 213.314 57.2682 214.064C56.868 214.813 56.6359 215.641 56.5879 216.489V219.954C56.6379 220.865 56.9015 221.752 57.3576 222.542C57.8136 223.333 58.4491 224.005 59.2129 224.504L197.008 304.045C197.821 304.459 198.72 304.675 199.633 304.675C200.545 304.675 201.445 304.459 202.258 304.045L274.358 262.423C275.122 261.926 275.758 261.255 276.214 260.466C276.67 259.676 276.933 258.79 276.983 257.88V254.38C276.933 253.545 276.706 252.73 276.316 251.99C275.926 251.25 275.383 250.602 274.722 250.089V250.089Z"
              fill="#455A64"
            />
            <path
              d="M274.4 249.858L136.563 170.289C135.75 169.878 134.852 169.664 133.941 169.664C133.031 169.664 132.133 169.878 131.32 170.289L59.2198 211.925C57.7708 212.758 57.7708 214.116 59.2198 214.949L197.022 294.518C197.835 294.929 198.733 295.143 199.643 295.143C200.554 295.143 201.452 294.929 202.265 294.518L274.365 252.882C275.8 252.049 275.8 250.691 274.4 249.858ZM255.703 243.425C256.942 244.125 256.942 245.301 255.703 246.015L190.141 283.878C189.443 284.23 188.672 284.413 187.89 284.413C187.109 284.413 186.338 284.23 185.64 283.878L78.3158 221.9C77.0768 221.2 77.0768 220.017 78.3158 219.303L143.885 181.447C145.124 180.747 146.419 180.313 147.658 181.027L255.703 243.425Z"
              fill="#37474F"
            />
            <path
              d="M58.9049 212.1C58.2268 212.612 57.6683 213.265 57.2682 214.015C56.868 214.764 56.6359 215.592 56.5879 216.44V219.905C56.6384 220.816 56.9023 221.702 57.3583 222.493C57.8142 223.283 58.4495 223.955 59.2129 224.455L197.008 304.045C197.818 304.473 198.724 304.689 199.64 304.675V295.141C198.726 295.158 197.823 294.944 197.015 294.518L59.2129 214.949C57.8759 214.2 57.7709 212.968 58.9049 212.1Z"
              fill="#263238"
            />
            <path
              d="M102.2 195.195C103.173 194.635 103.173 193.725 102.2 193.165C101.657 192.891 101.058 192.749 100.45 192.749C99.8421 192.749 99.2427 192.891 98.7 193.165C97.727 193.725 97.727 194.635 98.7 195.195C99.2427 195.469 99.8421 195.611 100.45 195.611C101.058 195.611 101.657 195.469 102.2 195.195V195.195Z"
              :fill="'#9078D4'"
            />
            <path
              d="M235.648 271.334C237.937 270.018 237.937 267.876 235.648 266.56C234.366 265.905 232.947 265.563 231.507 265.563C230.068 265.563 228.649 265.905 227.367 266.56C225.078 267.876 225.078 270.018 227.367 271.334C228.649 271.989 230.068 272.331 231.507 272.331C232.947 272.331 234.366 271.989 235.648 271.334V271.334Z"
              stroke="#E6E6E6"
              stroke-miterlimit="10"
            />
            <path
              d="M100.443 201.929L114.023 194.096C114.828 193.627 114.87 192.892 114.114 192.451C113.673 192.241 113.189 192.136 112.701 192.145C112.213 192.153 111.733 192.275 111.3 192.5L97.7198 200.333C96.9148 200.802 96.8728 201.537 97.6288 201.978C98.0697 202.188 98.5533 202.293 99.0416 202.284C99.5299 202.276 100.01 202.154 100.443 201.929V201.929Z"
              :fill="'#9078D4'"
            />
            <path
              d="M147.658 181.055C146.419 180.355 145.124 180.754 143.885 181.475L78.316 219.331C77.077 220.031 77.077 221.207 78.316 221.928L185.612 283.871C186.31 284.223 187.081 284.406 187.862 284.406C188.644 284.406 189.415 284.223 190.113 283.871L255.675 246.008C256.921 245.308 256.921 244.132 255.675 243.418L147.658 181.055Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.85"
              d="M147.658 181.055C146.419 180.355 145.124 180.754 143.885 181.475L78.316 219.331C77.077 220.031 77.077 221.207 78.316 221.928L185.612 283.871C186.31 284.223 187.081 284.406 187.862 284.406C188.644 284.406 189.415 284.223 190.113 283.871L255.675 246.008C256.921 245.308 256.921 244.132 255.675 243.418L147.658 181.055Z"
              fill="white"
            />
            <path
              d="M85.8689 222.733L85.1689 222.327C85.1367 222.315 85.109 222.294 85.0894 222.265C85.0698 222.237 85.0593 222.204 85.0593 222.17C85.0593 222.135 85.0698 222.102 85.0894 222.074C85.109 222.045 85.1367 222.024 85.1689 222.012L85.2459 221.97C85.3299 221.925 85.4237 221.902 85.5189 221.902C85.6141 221.902 85.7078 221.925 85.7919 221.97L86.4919 222.369C86.6389 222.46 86.6389 222.6 86.4919 222.684L86.4149 222.733C86.3298 222.774 86.2365 222.796 86.1419 222.796C86.0473 222.796 85.9539 222.774 85.8689 222.733V222.733Z"
              fill="#455A64"
            />
            <path
              d="M87.115 222.012L85.904 221.312C85.757 221.228 85.757 221.088 85.904 220.997L85.981 220.955C86.066 220.914 86.1594 220.892 86.254 220.892C86.3486 220.892 86.4419 220.914 86.527 220.955L87.738 221.655C87.885 221.739 87.885 221.879 87.738 221.97L87.661 222.012C87.5759 222.053 87.4826 222.075 87.388 222.075C87.2934 222.075 87.2 222.053 87.115 222.012V222.012Z"
              fill="#455A64"
            />
            <path
              d="M88.361 221.291L86.646 220.304C86.492 220.213 86.492 220.073 86.646 219.989L86.723 219.94C86.8081 219.899 86.9014 219.877 86.996 219.877C87.0906 219.877 87.184 219.899 87.269 219.94L88.984 220.934C89.0162 220.946 89.0439 220.967 89.0635 220.996C89.0831 221.024 89.0936 221.057 89.0936 221.091C89.0936 221.126 89.0831 221.159 89.0635 221.187C89.0439 221.216 89.0162 221.237 88.984 221.249L88.907 221.291C88.823 221.336 88.7292 221.359 88.634 221.359C88.5388 221.359 88.4451 221.336 88.361 221.291V221.291Z"
              fill="#455A64"
            />
            <path
              d="M89.5999 220.577L86.6389 218.862C86.4849 218.778 86.4849 218.638 86.6389 218.547L86.7159 218.505C86.8005 218.462 86.894 218.44 86.9889 218.44C87.0838 218.44 87.1774 218.462 87.2619 218.505L90.2159 220.213C90.3699 220.304 90.3699 220.444 90.2159 220.528L90.1459 220.577C90.0609 220.618 89.9675 220.64 89.8729 220.64C89.7783 220.64 89.685 220.618 89.5999 220.577V220.577Z"
              fill="#455A64"
            />
            <path
              d="M93.1771 218.743C93.1299 218.748 93.0824 218.736 93.0441 218.708L92.6101 218.456L91.2101 219.261C91.1801 219.281 91.1447 219.292 91.1086 219.292C91.0724 219.292 91.0371 219.281 91.0071 219.261L90.8461 219.17L90.7761 219.121V219.051L90.7061 217.294C90.7015 217.269 90.7015 217.242 90.7061 217.217C90.7292 217.19 90.7579 217.169 90.7901 217.154L90.9791 217.042C91.0286 217.013 91.0833 216.994 91.1401 216.986C91.1919 216.987 91.2426 217.001 91.2871 217.028L92.8061 217.903L93.1141 217.728C93.1601 217.697 93.213 217.678 93.2681 217.672C93.3179 217.672 93.3666 217.686 93.4081 217.714L93.5131 217.777C93.5621 217.777 93.5831 217.826 93.5761 217.854C93.5691 217.882 93.5761 217.903 93.4851 217.938L93.1771 218.12L93.6111 218.372C93.6601 218.372 93.6811 218.421 93.6741 218.449C93.6671 218.477 93.6391 218.505 93.5761 218.54L93.3381 218.673C93.2928 218.712 93.2366 218.737 93.1771 218.743V218.743ZM92.3301 218.19L91.2451 217.567L91.3151 218.778L92.3301 218.19Z"
              fill="#455A64"
            />
            <path
              d="M94.5698 217.637C94.0798 217.661 93.5934 217.541 93.1698 217.294C92.7358 217.042 92.5398 216.769 92.5748 216.475C92.6098 216.181 92.8828 215.894 93.3798 215.607C93.5759 215.491 93.782 215.392 93.9958 215.313C94.1836 215.24 94.3783 215.186 94.5768 215.152C94.6061 215.141 94.6385 215.141 94.6678 215.152C94.6707 215.163 94.6707 215.175 94.6678 215.187L94.7308 215.369C94.7377 215.377 94.7415 215.387 94.7415 215.397C94.7415 215.407 94.7377 215.417 94.7308 215.425H94.6538C94.3523 215.485 94.0634 215.596 93.7998 215.754C93.4638 215.943 93.2818 216.146 93.2538 216.349C93.2258 216.552 93.3798 216.755 93.7158 216.944C94.0229 217.132 94.3779 217.227 94.7378 217.217C95.0939 217.204 95.4408 217.1 95.7458 216.916C95.855 216.852 95.9602 216.782 96.0608 216.706C96.1406 216.649 96.2114 216.581 96.2708 216.503L95.5708 216.097L94.9898 216.433C94.9403 216.462 94.8856 216.481 94.8288 216.489C94.7793 216.487 94.731 216.473 94.6888 216.447L94.5908 216.391C94.5441 216.363 94.5231 216.335 94.5278 216.307C94.5278 216.307 94.5628 216.258 94.6188 216.223L95.5498 215.684C95.6049 215.651 95.6674 215.631 95.7318 215.628C95.789 215.629 95.8449 215.646 95.8928 215.677L96.9008 216.258C96.9291 216.269 96.9535 216.289 96.9708 216.314C96.9708 216.314 96.9708 216.349 96.9708 216.377C96.7508 216.706 96.4461 216.97 96.0888 217.14C95.6311 217.428 95.1093 217.599 94.5698 217.637V217.637Z"
              fill="#455A64"
            />
            <path
              d="M113.946 204.036L115.591 204.988C115.661 205.023 115.737 205.041 115.815 205.041C115.893 205.041 115.969 205.023 116.039 204.988L117.698 204.036C117.817 203.959 117.817 203.847 117.698 203.777L116.046 202.825C115.978 202.787 115.9 202.767 115.822 202.767C115.744 202.767 115.667 202.787 115.598 202.825L113.946 203.777C113.82 203.847 113.82 203.959 113.946 204.036Z"
              fill="#455A64"
            />
            <path
              d="M115.087 202.741C114.869 202.635 114.629 202.582 114.387 202.587C114.144 202.581 113.904 202.634 113.687 202.741C113.365 202.93 113.365 203.217 113.687 203.399L114.254 203.728L114.667 203.644L114.1 203.322C114.049 203.303 114.006 203.269 113.976 203.224C113.945 203.18 113.929 203.127 113.929 203.074C113.929 203.02 113.945 202.967 113.976 202.923C114.006 202.878 114.049 202.844 114.1 202.825C114.19 202.776 114.291 202.75 114.394 202.748C114.495 202.745 114.595 202.772 114.681 202.825L115.248 203.154L115.668 203.07L115.087 202.741Z"
              fill="#455A64"
            />
            <path
              d="M145.124 185.374C144.847 185.365 144.572 185.427 144.326 185.556L140.126 187.971C139.762 188.174 139.762 188.503 140.126 188.713L141.652 189.588C141.898 189.717 142.173 189.779 142.45 189.77C142.579 189.769 142.708 189.757 142.835 189.735C142.978 189.712 143.116 189.662 143.241 189.588L147.441 187.18C147.798 186.97 147.798 186.641 147.441 186.438L145.915 185.556C145.672 185.427 145.399 185.365 145.124 185.374ZM142.464 189.574C142.344 189.572 142.226 189.541 142.121 189.483L140.602 188.601C140.544 188.58 140.493 188.542 140.457 188.491C140.421 188.44 140.402 188.38 140.402 188.317C140.402 188.255 140.421 188.195 140.457 188.144C140.493 188.093 140.544 188.055 140.602 188.034L144.802 185.626C144.869 185.585 144.942 185.556 145.019 185.542H145.145C145.264 185.546 145.379 185.579 145.481 185.64L147 186.515C147.059 186.536 147.109 186.574 147.145 186.625C147.181 186.676 147.2 186.736 147.2 186.798C147.2 186.861 147.181 186.921 147.145 186.972C147.109 187.023 147.059 187.061 147 187.082L142.8 189.497C142.698 189.558 142.583 189.591 142.464 189.595V189.574Z"
              fill="#455A64"
            />
            <path
              d="M142.583 189.301L142.527 189.266L141.127 188.475C141.084 188.459 141.047 188.43 141.021 188.393C140.995 188.355 140.981 188.311 140.981 188.265C140.981 188.219 140.995 188.175 141.021 188.137C141.047 188.1 141.084 188.071 141.127 188.055L144.893 185.885C144.916 185.869 144.942 185.857 144.97 185.85L145.026 185.885L146.426 186.676C146.467 186.693 146.501 186.722 146.526 186.759C146.55 186.795 146.563 186.838 146.563 186.882C146.563 186.927 146.55 186.97 146.526 187.006C146.501 187.043 146.467 187.072 146.426 187.089L142.66 189.266L142.583 189.301Z"
              fill="#455A64"
            />
            <path
              d="M146.132 185.444C146.277 185.41 146.427 185.407 146.573 185.434C146.719 185.462 146.857 185.52 146.979 185.605L147.259 185.773C147.574 185.948 147.707 186.172 147.56 186.27L147.287 186.438L145.887 185.605L146.132 185.444Z"
              fill="#455A64"
            />
            <path
              d="M122.213 226.576C123.242 226.533 124.248 226.256 125.153 225.764C126.861 224.777 127.302 223.433 124.593 221.746C124.502 222.255 124.294 222.737 123.985 223.152C123.677 223.567 123.276 223.906 122.815 224.14C120.491 225.484 117.845 225.827 115.71 224.602C113.575 223.377 114.31 221.557 116.648 220.213C118.152 219.355 119.856 218.906 121.588 218.912C123.32 218.919 125.02 219.379 126.518 220.248C130.473 222.53 129.584 224.945 126.784 226.548C125.318 227.369 123.686 227.847 122.01 227.948L122.213 226.576ZM123.2 221.018C121.149 220.129 119.406 220.255 118.083 221.018C116.97 221.655 116.746 222.586 117.978 223.293C118.595 223.627 119.291 223.786 119.992 223.752C120.693 223.719 121.371 223.495 121.954 223.104C122.347 222.921 122.675 222.624 122.897 222.253C123.12 221.881 123.225 221.45 123.2 221.018V221.018Z"
              fill="#455A64"
            />
            <path
              d="M128.1 216.615C128.517 216.362 128.989 216.212 129.476 216.178C129.963 216.144 130.451 216.226 130.9 216.419C131.6 216.839 131.467 217.539 130.571 218.05C130.147 218.309 129.667 218.463 129.171 218.498C128.675 218.533 128.178 218.449 127.722 218.253C127.001 217.84 127.183 217.133 128.1 216.615ZM133.567 219.772C133.985 219.519 134.457 219.37 134.944 219.337C135.431 219.305 135.919 219.389 136.367 219.583C137.067 219.996 136.934 220.696 136.038 221.214C135.613 221.472 135.133 221.624 134.638 221.66C134.143 221.695 133.646 221.612 133.189 221.417C132.475 221.004 132.65 220.29 133.546 219.8L133.567 219.772Z"
              fill="#455A64"
            />
            <path
              d="M134.491 215.957C131.054 213.969 131.054 211.904 133.868 210.273C136.682 208.642 140.28 208.656 143.71 210.637C147.14 212.618 147.21 214.746 144.41 216.377C141.61 218.008 137.935 217.945 134.491 215.957ZM141.358 211.988C138.481 210.329 136.633 210.343 135.31 211.106C133.987 211.869 133.959 212.94 136.836 214.606C139.713 216.272 141.687 216.3 142.989 215.551C144.291 214.802 144.242 213.654 141.358 211.988Z"
              fill="#455A64"
            />
            <path
              d="M145.439 209.636C141.995 207.648 141.995 205.583 144.809 203.959C147.623 202.335 151.228 202.342 154.651 204.316C158.074 206.29 158.193 208.432 155.351 210.056C152.509 211.68 148.876 211.624 145.439 209.636ZM152.306 205.674C149.422 204.008 147.574 204.022 146.251 204.785C144.928 205.548 144.9 206.619 147.784 208.285C150.668 209.951 152.635 209.972 153.93 209.223C155.225 208.474 155.183 207.333 152.306 205.674Z"
              fill="#455A64"
            />
            <path
              d="M127.792 241.941L182.868 210.147C183.519 209.818 184.238 209.646 184.968 209.646C185.697 209.646 186.417 209.818 187.068 210.147L201.145 218.274C202.307 218.974 202.307 220.031 201.145 220.703L146.09 252.497C145.439 252.826 144.719 252.998 143.99 252.998C143.26 252.998 142.541 252.826 141.89 252.497L127.792 244.37C126.63 243.698 126.63 242.613 127.792 241.941Z"
              :fill="'#9078D4'"
            />
            <path
              d="M133.7 243.019L143.556 248.703C143.836 248.848 144.147 248.923 144.462 248.923C144.778 248.923 145.089 248.848 145.369 248.703L159.894 240.303C160.398 240.009 160.398 239.54 159.894 239.253L150.038 233.562C149.757 233.42 149.446 233.347 149.131 233.347C148.816 233.347 148.506 233.42 148.225 233.562L133.7 241.962C133.587 241.997 133.488 242.067 133.418 242.162C133.347 242.257 133.31 242.372 133.31 242.49C133.31 242.609 133.347 242.724 133.418 242.819C133.488 242.914 133.587 242.984 133.7 243.019V243.019Z"
              fill="#FAFAFA"
            />
            <path
              d="M148.568 240.282L160.251 239.82L148.61 239.645L149.079 233.366L147.168 241.115L133.315 242.487L144.627 241.955L144.347 248.92L145.215 241.927L148.456 241.773L148.568 240.282Z"
              :fill="'#9078D4'"
            />
            <path
              d="M154.7 230.594L168.931 222.411C169.286 222.231 169.678 222.138 170.075 222.138C170.473 222.138 170.865 222.231 171.22 222.411C171.843 222.768 171.822 223.356 171.171 223.734L156.94 231.917C156.585 232.097 156.193 232.19 155.795 232.19C155.398 232.19 155.006 232.097 154.651 231.917C154.035 231.56 154.063 231 154.7 230.594Z"
              fill="#FAFAFA"
            />
            <path
              d="M159.089 233.1L187.929 216.433C188.284 216.253 188.676 216.16 189.073 216.16C189.471 216.16 189.863 216.253 190.218 216.433C190.841 216.79 190.82 217.385 190.169 217.756L161.329 234.423C160.973 234.602 160.579 234.695 160.181 234.695C159.782 234.695 159.389 234.602 159.033 234.423C158.417 234.087 158.438 233.499 159.089 233.1Z"
              fill="#FAFAFA"
            />
            <path
              d="M163.464 235.648L192.311 218.981C192.666 218.801 193.058 218.708 193.455 218.708C193.853 218.708 194.245 218.801 194.6 218.981C195.216 219.338 195.195 219.933 194.551 220.304L165.704 236.971C165.349 237.151 164.957 237.244 164.559 237.244C164.162 237.244 163.77 237.151 163.415 236.971C162.792 236.6 162.82 236.026 163.464 235.648Z"
              fill="#FAFAFA"
            />
            <path
              d="M121.485 127.078L214.851 73.178L218.589 75.334C219.098 75.6693 219.522 76.1195 219.826 76.6483C220.13 77.1772 220.306 77.77 220.339 78.379V147.252C220.306 147.861 220.131 148.454 219.827 148.983C219.523 149.512 219.099 149.962 218.589 150.297L128.73 202.167C128.186 202.438 127.587 202.579 126.98 202.579C126.373 202.579 125.774 202.438 125.23 202.167L123.256 201.026C122.746 200.691 122.321 200.241 122.016 199.712C121.711 199.183 121.534 198.59 121.499 197.981L121.485 127.078Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.05"
              d="M121.485 127.078L214.851 73.178L218.589 75.334C219.098 75.6693 219.522 76.1195 219.826 76.6483C220.13 77.1772 220.306 77.77 220.339 78.379V147.252C220.306 147.861 220.131 148.454 219.827 148.983C219.523 149.512 219.099 149.962 218.589 150.297L128.73 202.167C128.186 202.438 127.587 202.579 126.98 202.579C126.373 202.579 125.774 202.438 125.23 202.167L123.256 201.026C122.746 200.691 122.321 200.241 122.016 199.712C121.711 199.183 121.534 198.59 121.499 197.981L121.485 127.078Z"
              :fill="'#9078D4'"
            />
            <path
              d="M214.333 67.116L214.718 66.906L213.269 66.066C213.143 65.9953 213.012 65.9345 212.877 65.884L171.787 50.659C171.224 50.47 170.615 50.4652 170.048 50.6451C169.482 50.8251 168.988 51.1805 168.637 51.66L122.668 118.783C121.954 119.94 121.547 121.261 121.485 122.619V127.078L122.885 127.904L214.83 73.171V68.712C214.817 68.144 214.644 67.591 214.333 67.116V67.116Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.1"
              d="M170.534 51.989C170.351 52.1323 170.191 52.3022 170.058 52.493L124.089 119.616C123.711 120.21 123.424 120.856 123.235 121.534L121.835 120.708C121.939 120.363 122.062 120.024 122.206 119.693C122.269 119.553 122.332 119.42 122.402 119.287V119.224C122.475 119.084 122.557 118.948 122.647 118.818L168.63 51.667C168.699 51.5676 168.776 51.4739 168.861 51.387L169.106 51.163L170.534 51.989Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.35"
              d="M214.697 66.9059C214.573 66.8304 214.441 66.7671 214.305 66.7169L173.208 51.4849C172.756 51.3347 172.273 51.3021 171.805 51.3903C171.338 51.4785 170.9 51.6845 170.534 51.9889L169.134 51.1629C169.503 50.8614 169.942 50.6572 170.41 50.5692C170.878 50.4811 171.361 50.512 171.815 50.6589L212.905 65.8839C213.039 65.9341 213.168 65.9974 213.29 66.0729L214.697 66.9059Z"
              fill="white"
            />
            <path
              d="M122.423 119.189C122.498 119.049 122.579 118.914 122.668 118.783C122.579 118.914 122.498 119.049 122.423 119.189Z"
              :fill="'#9078D4'"
            />
            <path
              d="M168.868 51.387C168.943 51.3052 169.025 51.2302 169.113 51.163C169.023 51.2284 168.941 51.3036 168.868 51.387V51.387Z"
              :fill="'#9078D4'"
            />
            <path
              d="M121.8 120.708C121.904 120.36 122.028 120.019 122.171 119.686C122.028 120.019 121.904 120.36 121.8 120.708V120.708Z"
              :fill="'#9078D4'"
            />
            <path
              d="M122.192 119.658C122.255 119.518 122.325 119.385 122.388 119.259C122.325 119.385 122.255 119.518 122.192 119.658Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.2"
              d="M122.388 119.259C122.388 119.259 122.388 119.21 122.388 119.189C122.388 119.168 122.402 119.238 122.388 119.259Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.2"
              d="M168.637 51.66C168.704 51.5611 168.782 51.4696 168.868 51.387C168.78 51.4677 168.702 51.5595 168.637 51.66V51.66Z"
              :fill="'#9078D4'"
            />
            <path
              d="M170.058 52.5L124.096 119.63C123.376 120.783 122.966 122.101 122.906 123.459V127.918L216.272 74.018V69.538C216.237 68.9345 216.035 68.3526 215.687 67.858C215.34 67.3633 214.861 66.9754 214.305 66.738L173.215 51.513C172.652 51.3264 172.044 51.3213 171.478 51.4984C170.911 51.6754 170.415 52.0258 170.058 52.5V52.5Z"
              :fill="'#9078D4'"
            />
            <path
              d="M133.07 192.787L130.074 191.058C129.827 190.869 129.632 190.62 129.51 190.334C129.387 190.049 129.34 189.736 129.374 189.427V96.075C129.413 95.1273 129.703 94.207 130.214 93.408L133.259 95.158C132.744 95.9602 132.454 96.8862 132.419 97.839V191.156C132.382 191.461 132.422 191.77 132.536 192.055C132.649 192.34 132.833 192.592 133.07 192.787V192.787Z"
              fill="#E0E0E0"
            />
            <path
              d="M134.939 93.38L209.937 50.092C211.337 49.266 212.506 49.931 212.506 51.576V144.9C212.459 145.793 212.202 146.662 211.756 147.437C211.309 148.212 210.686 148.871 209.937 149.359L134.939 192.64C133.539 193.459 132.37 192.794 132.37 191.149V97.839C132.418 96.9463 132.676 96.0776 133.122 95.3029C133.569 94.5283 134.191 93.8696 134.939 93.38V93.38Z"
              fill="#F0F0F0"
            />
            <path
              d="M208.712 48.153L211.652 49.861C211.367 49.7549 211.061 49.7217 210.76 49.7645C210.459 49.8073 210.174 49.9247 209.93 50.106L134.939 93.387C134.22 93.8207 133.622 94.4301 133.203 95.158L130.158 93.408C130.58 92.6812 131.174 92.0685 131.887 91.623L206.885 48.3C207.145 48.1083 207.454 47.9918 207.776 47.9634C208.098 47.935 208.422 47.9958 208.712 48.139V48.153Z"
              fill="#FAFAFA"
            />
            <path
              d="M140.791 98.266L203.154 62.258C203.994 61.775 204.673 62.118 204.673 63.028C204.635 63.5367 204.478 64.0293 204.214 64.4659C203.95 64.9025 203.587 65.2708 203.154 65.541L140.791 101.549C139.951 102.032 139.272 101.689 139.272 100.786C139.309 100.276 139.466 99.782 139.73 99.3441C139.994 98.9062 140.357 98.5368 140.791 98.266Z"
              fill="#E0E0E0"
            />
            <path
              d="M140.791 104.811L203.154 68.803C203.994 68.32 204.673 68.663 204.673 69.573C204.635 70.0818 204.478 70.5744 204.214 71.011C203.95 71.4476 203.587 71.8159 203.154 72.086L140.791 108.094C139.951 108.577 139.272 108.234 139.272 107.331C139.309 106.821 139.466 106.327 139.73 105.889C139.994 105.451 140.357 105.082 140.791 104.811V104.811Z"
              fill="#E0E0E0"
            />
            <path
              d="M140.791 111.356L203.154 75.348C203.994 74.865 204.673 75.208 204.673 76.111C204.636 76.621 204.479 77.115 204.215 77.5529C203.951 77.9908 203.588 78.3602 203.154 78.631L140.791 114.639C139.951 115.122 139.272 114.779 139.272 113.876C139.309 113.366 139.466 112.872 139.73 112.434C139.994 111.996 140.357 111.627 140.791 111.356V111.356Z"
              fill="#E0E0E0"
            />
            <path
              d="M140.791 117.901L203.154 81.9C203.994 81.417 204.673 81.76 204.673 82.663C204.636 83.173 204.479 83.667 204.215 84.1049C203.951 84.5428 203.588 84.9122 203.154 85.183L140.791 121.191C139.951 121.674 139.272 121.331 139.272 120.421C139.309 119.911 139.466 119.417 139.73 118.979C139.994 118.541 140.357 118.172 140.791 117.901V117.901Z"
              fill="#E0E0E0"
            />
            <path
              d="M140.791 124.446L203.154 88.438C203.994 87.955 204.673 88.298 204.673 89.201C204.636 89.7109 204.479 90.205 204.215 90.6429C203.951 91.0808 203.588 91.4501 203.154 91.721L140.791 127.729C139.951 128.212 139.272 127.869 139.272 126.959C139.31 126.45 139.467 125.958 139.731 125.521C139.995 125.084 140.358 124.716 140.791 124.446Z"
              fill="#E0E0E0"
            />
            <path
              d="M140.791 130.991L203.154 94.983C203.994 94.5 204.673 94.843 204.673 95.746C204.636 96.256 204.479 96.75 204.215 97.1879C203.951 97.6258 203.588 97.9952 203.154 98.266L140.791 134.274C139.951 134.757 139.272 134.414 139.272 133.504C139.31 132.995 139.467 132.503 139.731 132.066C139.995 131.629 140.358 131.261 140.791 130.991Z"
              fill="#E0E0E0"
            />
            <path
              d="M140.791 137.536L203.154 101.5C203.994 101.017 204.673 101.36 204.673 102.263C204.636 102.773 204.479 103.267 204.215 103.705C203.951 104.143 203.588 104.512 203.154 104.783L140.791 140.791C139.951 141.274 139.272 140.931 139.272 140.021C139.314 139.517 139.474 139.03 139.737 138.599C140.001 138.167 140.362 137.803 140.791 137.536V137.536Z"
              fill="#E0E0E0"
            />
            <path
              d="M140.791 144.081L203.154 108.073C203.994 107.583 204.673 107.933 204.673 108.836C204.636 109.346 204.479 109.84 204.215 110.278C203.951 110.716 203.588 111.085 203.154 111.356L140.791 147.364C139.951 147.847 139.272 147.504 139.272 146.594C139.31 146.085 139.467 145.593 139.731 145.156C139.995 144.719 140.358 144.351 140.791 144.081Z"
              fill="#E0E0E0"
            />
            <path
              d="M217.973 148.162C217.945 148.212 217.912 148.259 217.875 148.302L178.969 131.047C177.492 130.393 175.828 130.296 174.285 130.772C172.741 131.248 171.422 132.265 170.569 133.637L128.975 200.375L127.435 201.264C127.263 201.384 127.065 201.459 126.857 201.482C126.649 201.505 126.439 201.476 126.245 201.397L169.022 132.755C169.877 131.385 171.196 130.369 172.738 129.892C174.281 129.415 175.943 129.51 177.422 130.158L217.973 148.162Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.4"
              d="M166.726 139.706L166.824 139.622L128.961 200.382L127.421 201.271C127.249 201.389 127.05 201.463 126.843 201.486C126.635 201.509 126.425 201.481 126.231 201.404L165.2 138.81L166.726 139.706Z"
              fill="white"
            />
            <path
              opacity="0.4"
              d="M217.973 148.169C217.945 148.219 217.912 148.266 217.875 148.309L178.962 131.047C177.485 130.393 175.821 130.296 174.278 130.772C172.734 131.248 171.415 132.265 170.562 133.637L169.022 132.741C169.88 131.375 171.2 130.363 172.743 129.89C174.285 129.417 175.946 129.515 177.422 130.165L217.973 148.169Z"
              fill="white"
            />
            <path
              d="M220.311 77.9309C220.227 77.4269 219.961 77.2869 219.611 77.5249C219.438 77.6541 219.287 77.81 219.163 77.987L218.967 78.2599L180.6 131.712L174.867 139.699C174.498 140.164 174.009 140.52 173.453 140.729C172.897 140.937 172.294 140.99 171.71 140.882L166.761 139.706L128.961 130.718C128.707 130.666 128.445 130.666 128.191 130.718C128.092 130.738 127.996 130.768 127.904 130.809L128.947 130.207L165.347 138.866L170.31 140C170.894 140.11 171.497 140.058 172.054 139.849C172.61 139.64 173.099 139.284 173.467 138.817L179.2 130.837L217.784 77.112C217.902 76.9522 218.049 76.8168 218.218 76.7129L214.9 74.8019L216.3 73.9969L218.61 75.3269C219.053 75.6256 219.432 76.0098 219.724 76.457C220.016 76.9042 220.216 77.4054 220.311 77.9309V77.9309Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.4"
              d="M180.6 131.712L174.867 139.699C174.498 140.164 174.009 140.52 173.453 140.729C172.897 140.937 172.294 140.99 171.71 140.882L166.761 139.706L166.859 139.622L167.097 139.244L170.338 140.014C170.922 140.124 171.525 140.072 172.082 139.863C172.638 139.655 173.127 139.298 173.495 138.831L179.172 130.935L180.6 131.712Z"
              fill="white"
            />
            <path
              opacity="0.1"
              d="M218.582 75.3269L216.272 73.9969L214.872 74.8019L218.211 76.7269C218.042 76.8313 217.895 76.9667 217.777 77.1259L179.2 130.851L179.144 130.928L180.544 131.705L218.96 78.26L219.156 77.987C219.28 77.81 219.431 77.6541 219.604 77.5249C219.947 77.2869 220.213 77.4269 220.304 77.9309C220.206 77.4037 220.003 76.9015 219.707 76.4542C219.412 76.0069 219.029 75.6236 218.582 75.3269V75.3269Z"
              :fill="'#9078D4'"
            />
            <path
              d="M129.717 131.838L121.485 127.078V197.981C121.52 198.59 121.697 199.183 122.002 199.712C122.307 200.241 122.732 200.691 123.242 201.026L125.216 202.167C125.76 202.438 126.359 202.579 126.966 202.579C127.573 202.579 128.172 202.438 128.716 202.167L129.703 201.593L129.717 131.838Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.15"
              d="M129.717 131.838L121.485 127.078V197.981C121.52 198.59 121.697 199.183 122.002 199.712C122.307 200.241 122.732 200.691 123.242 201.026L125.216 202.167C125.76 202.438 126.359 202.579 126.966 202.579C127.573 202.579 128.172 202.438 128.716 202.167L129.703 201.593L129.717 131.838Z"
              :fill="'#9078D4'"
            />
            <path
              d="M218.967 78.26V146.384C218.949 147.015 218.76 147.629 218.421 148.162C218.164 148.614 217.813 149.005 217.392 149.31L217.329 149.352L217.21 149.429L127.358 201.299C127.25 201.366 127.135 201.42 127.015 201.46C127.007 201.355 127.007 201.25 127.015 201.145V132.3C126.999 132.053 127.044 131.807 127.145 131.581C127.246 131.355 127.4 131.158 127.594 131.005C127.788 130.852 128.016 130.748 128.259 130.703C128.502 130.658 128.753 130.673 128.989 130.746L166.789 139.734L171.738 140.91C172.322 141.018 172.924 140.965 173.481 140.757C174.037 140.548 174.526 140.192 174.895 139.727L180.6 131.712L218.967 78.26Z"
              fill="#455A64"
            />
            <path
              d="M220.346 78.4V147.273C220.324 147.903 220.135 148.517 219.8 149.051C219.539 149.502 219.189 149.895 218.771 150.206L218.701 150.241C218.665 150.269 218.628 150.295 218.589 150.318L128.737 202.16C128.565 202.282 128.365 202.359 128.156 202.383C127.947 202.408 127.735 202.379 127.54 202.3C127.391 202.213 127.265 202.091 127.174 201.945C127.082 201.798 127.028 201.632 127.015 201.46C127.007 201.355 127.007 201.25 127.015 201.145V132.3C126.999 132.053 127.044 131.807 127.145 131.581C127.246 131.355 127.4 131.158 127.594 131.005C127.788 130.852 128.016 130.749 128.259 130.703C128.502 130.658 128.753 130.673 128.989 130.746L166.789 139.734L171.738 140.91C172.322 141.018 172.924 140.965 173.481 140.757C174.037 140.548 174.526 140.192 174.895 139.727L180.6 131.712L218.995 78.26L219.191 77.987C219.8 77.084 220.346 77.252 220.346 78.4Z"
              :fill="'#9078D4'"
            />
            <path
              d="M218.589 150.29L218.701 150.213L218.771 150.178C219.189 149.867 219.539 149.474 219.8 149.023C220.127 148.498 220.312 147.898 220.339 147.28L180.6 131.712L207.2 156.863L218.589 150.29Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.2"
              d="M218.589 150.29L218.701 150.213L218.771 150.178C219.189 149.867 219.539 149.474 219.8 149.023C220.127 148.498 220.312 147.898 220.339 147.28L180.6 131.712L207.2 156.863L218.589 150.29Z"
              :fill="'#9078D4'"
            />
            <path
              d="M126.98 201.152C126.955 201.376 126.992 201.603 127.089 201.807C127.185 202.011 127.337 202.185 127.526 202.307C127.721 202.386 127.933 202.415 128.143 202.39C128.352 202.366 128.552 202.289 128.723 202.167L159.656 184.31L166.719 139.713L126.98 200.004V201.152Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.15"
              d="M170.541 133.63L127.519 202.3C127.33 202.177 127.178 202.004 127.082 201.8C126.985 201.596 126.948 201.369 126.973 201.145V200.004L164.934 139.3L165.228 138.831L169.001 132.783L170.541 133.63Z"
              :fill="'#9078D4'"
            />
            <path
              d="M219.8 149.023C219.539 149.474 219.189 149.867 218.771 150.178L218.701 150.213L128.737 202.16C128.565 202.282 128.366 202.359 128.156 202.383C127.947 202.408 127.735 202.379 127.54 202.3L170.562 133.609C171.425 132.231 172.755 131.21 174.31 130.733C175.864 130.256 177.538 130.355 179.025 131.012L219.8 149.023Z"
              :fill="'#9078D4'"
            />
            <path
              d="M219.8 149.03C219.543 149.482 219.192 149.873 218.771 150.178L218.708 150.22L128.73 202.167C128.557 202.287 128.357 202.362 128.148 202.385C127.939 202.408 127.728 202.379 127.533 202.3L128.73 200.396L217.147 149.338L217.217 149.296C217.589 149.023 217.906 148.681 218.148 148.288L219.8 149.03Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.15"
              d="M121.8 120.708L123.2 121.534C123.002 122.155 122.891 122.8 122.871 123.452V127.904L121.471 127.078V122.619C121.491 121.97 121.602 121.327 121.8 120.708V120.708Z"
              :fill="'#9078D4'"
            />
            <path
              d="M130.256 130.494L128.94 130.179L127.897 130.781L126.455 129.948L122.906 127.904L124.285 127.085L130.256 130.494Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.1"
              d="M130.256 130.494L124.292 127.092L122.892 127.911L126.441 129.955L127.883 130.788C127.967 130.746 128.056 130.718 128.149 130.704C128.403 130.652 128.665 130.652 128.919 130.704L131.824 131.404L164.934 139.3L165.228 138.831L130.256 130.494Z"
              :fill="'#9078D4'"
            />
            <path
              d="M250.95 143.738L244.307 139.888L244.251 139.853C241.353 138.215 237.37 138.453 232.967 141.008C224.105 146.125 216.916 158.571 216.916 168.805C216.916 173.915 218.715 177.506 221.606 179.186L227.661 182.686L229.061 180.313C230.679 179.955 232.232 179.35 233.667 178.521C242.529 173.404 249.718 160.958 249.718 150.724C249.717 149.341 249.547 147.964 249.214 146.622L250.95 143.738Z"
              :fill="'#9078D4'"
            />
            <path
              opacity="0.15"
              d="M217.504 163.422C217.112 165.192 216.908 166.999 216.895 168.812C216.895 173.922 218.694 177.513 221.585 179.193L227.64 182.693L229.04 180.32C230.658 179.962 232.211 179.357 233.646 178.528C235.214 177.61 236.681 176.529 238.021 175.301L217.504 163.422Z"
              :fill="'#9078D4'"
            />
            <path
              d="M239.267 144.655C230.405 149.772 223.216 162.218 223.216 172.452C223.216 182.686 230.405 186.837 239.267 181.72C248.129 176.603 255.318 164.157 255.318 153.923C255.318 143.689 248.136 139.538 239.267 144.655Z"
              :fill="'#9078D4'"
            />
            <path
              d="M245.609 158.9V154.966C245.609 150.479 242.879 148.414 239.519 150.36C236.152 152.306 233.422 157.514 233.422 162.001V165.501C233.348 167.93 232.641 170.297 231.371 172.368C231.058 172.917 230.888 173.535 230.874 174.167V174.167C230.874 175.294 231.574 175.819 232.407 175.329L246.638 167.111C247.105 166.776 247.486 166.336 247.753 165.827C248.019 165.317 248.162 164.753 248.171 164.178V164.178C248.193 163.959 248.164 163.737 248.086 163.531C248.008 163.324 247.884 163.139 247.723 162.988V162.988C246.435 162.442 245.609 161 245.609 158.9Z"
              fill="white"
            />
            <path
              d="M242.438 170.8C242.438 172.956 241.129 175.455 239.519 176.4C237.909 177.345 236.593 176.344 236.593 174.195L242.438 170.8Z"
              fill="white"
            />
          </svg>
        </div>
        <div>
          <a-button
            @click.prevent="openMeltaApp"
            type="primary"
            html-type="submit"
            class="text-center w-100"
            :loading="loading"
          >
            <strong>Go To Melta's App</strong>
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyEmail',
  setup() {
    const openMeltaApp = () => {
      window.open('melta://edit_email')
    }

    return {
      openMeltaApp,
    }
  },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
</style>
