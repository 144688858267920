<template>
  <div>
    <verify-email />
  </div>
</template>
<script>
import VerifyEmail from '@/components/user/VerifyEmail'
import apiClient from '@/services/axios'

export default {
  components: {
    VerifyEmail,
  },
  beforeCreate() {
    if (this.$route.query.token) {
      try {
        const token = this.$route.query.token
        // console.log('this.$route.query.token', this.$route.query.token)
        apiClient.post('/account/verify-email', {
         token,
        })
      } catch (err) {
        console.log('err beforeCreate VerifyEmail =>', err)
      }
    }
  },
}
</script>
